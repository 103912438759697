(function () {
   "use strict";

   let app = angular.module("vdsr")

   console.log("in stw2fauthverify");

   app.directive("stw2fAuthVerify", DirectiveDef);

   function DirectiveDef() {
      return {
         restrict: "EA",
         transclude: true,
         scope: {
            methodInfo: '=',
            onComplete: "=",
            forDisable: '=', 
            forChangeVerification: "=",
            newContactInfo: "=",
            setupData: '='
         },
         templateUrl: "stw-2f-auth-verify.html",
         controller: DirectiveCtrl
      };
   }


   DirectiveCtrl.$inject = ["$scope", 'apiauth', 'taskWrap', 'countdown'];
   /**
   * 
   * @param {angular.IScope} scope 
   * @param {apiAuthService} apiauth
   * @param {taskWrap} taskWrap
   * @param {countdownService} countdown
   */
   function DirectiveCtrl(scope, apiauth, taskWrap, countdown) {
      console.log("got here")

      console.log("scope.onComplete: ", scope.onComplete);

      let bag = {
         twoFactorErrorMessage: "",
         codeEntered: '',
         app: {
            verify: buildVerify('app'),
            initiation: {
               running: false, runError: '', qrCodeImageData: ''
            },
            initiate: initiateApp,
            verification: { running: false },
            shouldShowTime: false,
            sendBtnText: 'Send Code',
            /** @type {CountdownInstance | null} */
            counter: null,
            codeEntered: '',
            verified: false
         },
         email: {
            verify: buildVerify('email'),
            initiation: {
               running: false, runError: '', email: '', codeExpiresInSeconds: 0
            },
            initiate: initiateEmail,
            /** @type {CountdownInstance | null} */
            counter: null,
            verification: {running: false},
            shouldShowTime: false,
            sendBtnText: 'Send Code',
            codeEntered: '',
            verified: false
         },
         sms: {
            initiation: {
               running: false, runError: '', phone: '', codeExpiresInSeconds: 0
            },
            verification: { running: false },
            verify: buildVerify('sms'),
            initiate : initiateSMS,
            /** @type {CountdownInstance | null} */
            counter: null,
            shouldShowTime: false,
            sendBtnText: 'Send Code',
            codeEntered: "",
            verified: false
         }
      };

      activate();
      function activate() {
         scope.bag = bag;
         scope.verifyClick = () => scope.onVerifyClick({code: bag.codeEntered});
         scope.timeExpired = timeExpired;
         scope.sendCodeClick = sendCodeClick;
         console.log("scope methodinfo : ", scope.methodInfo)
         if(scope.methodInfo.name === 'app' && !scope.forDisable){
            initiateApp();
         }
      }

      /**
       * 
       * @param {*} evt 
       * @param {TFMethodName } methodName 
       */
      function sendCodeClick(evt, methodName){
         evt.stopPropagation();
         if(!scope.forDisable && !scope.forChangeVerification){
            bag[methodName].initiate();
            return;
         }
         console.log("new contact info : ", scope.newContactInfo);
         taskWrap("set", false, bag[methodName].initiation, () => apiauth.sendCode(methodName, scope.newContactInfo).then(result => {
            if (result.codeExpirationInSeconds) {
               bag[methodName].counter = countdown.create(result.codeExpirationInSeconds);
               bag[methodName].shouldShowTime = true;
               bag[methodName].sendBtnText = 'Resend code';
            }
            return result;
         }));
      }

      function initiateApp() {
         console.log("what", apiauth, apiauth.initiateApp)
         taskWrap("set", true, bag.app.initiation, () =>  apiauth.initiateApp(scope.bag.isDefault, scope.setupData));
      }

      function initiateEmail() {
         taskWrap("set", true, bag.email.initiation, () => apiauth.initiateEmail(scope.bag.isDefault, scope.setupData).then(result => {
            bag.email.counter = countdown.create(result.codeExpirationInSeconds); 
            bag.email.shouldShowTime = true;
            bag.email.sendBtnText = 'Resend code';
            return result;
         }));
      }

      function initiateSMS() {
         taskWrap("set", true, bag.sms.initiation, () => apiauth.initiateSms(scope.bag.isDefault, scope.setupData).then(result => {
            bag.sms.counter = countdown.create(result.codeExpirationInSeconds); 
            bag.sms.shouldShowTime = true;
            bag.sms.sendBtnText = 'Resend code';
            return result;
         }));
      }

      /**
       * 
       * @param {TFMethodName} methodName 
       * @returns 
       */
      function buildVerify(methodName){
         return function(evt){
            scope.bag.twoFactorErrorMessage = '';
            evt.stopPropagation();
            let errFn = (e)=>{ scope.bag.twoFactorErrorMessage = 'This code is invalid'; console.log("Error in build verify: ", e)};
            if(!scope.forDisable && !scope.forChangeVerification){
               taskWrap("alert", false, bag[methodName].verification,
                  () => apiauth.verifyAndEnable(bag[methodName].codeEntered, methodName, scope.setupData).then(result =>{
                     bag[methodName].verified = true;
                     return scope.onComplete(methodName, result);
                  }),
                  errFn);
            }
            if(scope.forDisable){
               taskWrap("alert", false, bag[methodName].verification, 
                  () => apiauth.disable(bag[methodName].codeEntered, methodName).then(result => 
                     scope.onComplete(methodName, result, true)
                  ),
                  errFn);
            }
            if(scope.forChangeVerification){
               taskWrap("alert", false, bag[methodName].verification, 
                  () => apiauth.verify(bag[methodName].codeEntered, methodName).then(result => {
                     bag[methodName].verified = true;
                     return scope.onComplete(methodName, result, true)
                  }),
                  errFn); 
            }
         }
      }

      /**
       * @param {string} code
       * @param {{running?: boolean}} context
       */
      // function disable(code, context) {
      //    taskWrap("alert", false, context, 
      //       () => auth.disable(code).then(result => {
      //          bag.currentState.result = result;
      //          bag.selectedMethod = null;
      //          !result.enabledMethod && ui.alertSuccess("You have successfully disabled two factor authentication")
      //       })
      //    );
      // }
      // function sendCodeBeforeDisable() {
      //    taskWrap("set", false, bag.disableState.preparation, () => auth.sendCode().then(result => {
      //       if (result.codeExpirationInSeconds) {
      //          bag.disableState.counter = countdown.create(result.codeExpirationInSeconds);
      //       }
      //       return result;
      //    }));
      // }

      /**
       * 
       * @param {TFMethodName} methodName 
       */
      function timeExpired(methodName) {
         if(!methodName || !bag[methodName]) return
         if(bag[methodName].counter !== null){
            /** @ts-ignore */
            return bag[methodName].shouldShowTime && bag[methodName].counter.remaining <= 0;
         }
      }
   }
})();
